import { useQuery, useMutation, UseQueryOptions, UseMutationOptions } from 'react-query'

import {
  createDesignatedEntity,
  createDesignatedVendor,
  destroyDesignatedEntity,
  destroyDesignatedVendor,
  destroyClientUser,
  enableClientUser,
  listClientUsers,
  listDesignatedEntities,
  listDesignatedVendors,
  partialUpdateClientUser,
  retrieveClientUser,
  updateDesignatedEntity,
  updateDesignatedVendor,
  createClientUser,
  listDesignee,
  listAssignableClientUsers,
} from '../api'
import { ERole } from '../enum'
import {
  ICreateDesignatedEntityRequest,
  ICreateDesignatedVendorRequest,
  IDestroyDesignatedEntityRequest,
  IDestroyDesignatedVendorRequest,
  IError,
  IListClientUsersResponse,
  IListDesignatedEntityResponse,
  IListDesignatedVendorResponse,
  IListDesigneeResponse,
  IListUserRequest,
  IListUserResponse,
  IOrgUser,
  IUpdateClientUserRequest,
  IUpdateDesignatedEntityRequest,
  IUpdateDesignatedVendorRequest,
  IUserRequest,
  IUserResponse,
} from '../interface'

export const ClientUserQueryKeys = {
  GET_ASSIGNABLE_CLIENT_USERS: 'GET_ASSIGNABLE_CLIENT_USERS',
  GET_CLIENT_USERS: 'GET_CLIENT_USERS',
  GET_CLIENT_USER: 'GET_CLIENT_USER',
  GET_CLIENT_DESIGNATED_ENTITY: 'GET_CLIENT_DESIGNATED_ENTITY',
  GET_CLIENT_DESIGNATED_VENDOR: 'GET_CLIENT_DESIGNATED_VENDOR',
}

export const useAssignableClientUsers = (
  clientGuid: string,
  page?: number,
  page_size?: number,
  search?: string
) =>
  useQuery<IListUserResponse, IError>(
    [ClientUserQueryKeys.GET_CLIENT_USERS, clientGuid, page, page_size, search],
    () => listAssignableClientUsers(clientGuid, page, page_size, search)
  )

export const useClientUsers = (
  clientGuid: string,
  params: IListUserRequest,
  config?: UseQueryOptions<IListClientUsersResponse, IError, IListClientUsersResponse>
) =>
  useQuery<IListClientUsersResponse, IError>(
    [ClientUserQueryKeys.GET_CLIENT_USERS, clientGuid, params],
    () => listClientUsers(clientGuid, params),
    config
  )

export const useClientUser = (clientGuid: string, userGuid: string) =>
  useQuery<IOrgUser, IError>([ClientUserQueryKeys.GET_CLIENT_USER, clientGuid, userGuid], () =>
    retrieveClientUser(clientGuid, userGuid)
  )

export const useCreateClientUser = () =>
  useMutation<
    IUserResponse,
    IError,
    {
      clientGuid: string
      user: IUserRequest | string
      role: ERole | string
      should_update?: boolean
      client_relation?: string
      client?: string
      is_qbo_class?: boolean
    }
  >(createClientUser)

export const useUpdateClientUser = (
  config?: UseMutationOptions<IOrgUser, IError, IUpdateClientUserRequest>
) => useMutation<IOrgUser, IError, IUpdateClientUserRequest>(partialUpdateClientUser, config)

export const useDestroyClientUser = () =>
  useMutation<undefined, IError, { clientGuid: string; userGuid: string }>(destroyClientUser)

export const useEnableClientUser = () =>
  useMutation<undefined, IError, { clientGuid: string; userGuid: string }>(enableClientUser)

export const useDesignatedEntityList = (
  clientGuid: string,
  userGuid: string,
  config?: UseQueryOptions<IListDesignatedEntityResponse, IError>
) =>
  useQuery<IListDesignatedEntityResponse, IError>(
    [ClientUserQueryKeys.GET_CLIENT_DESIGNATED_ENTITY, { clientGuid, userGuid }],
    () => listDesignatedEntities(clientGuid, userGuid),
    config
  )

export const useDesigneeList = (
  clientGuid: string,
  entityGuid: string,
  params: IListUserRequest,
  config?: UseQueryOptions<IListDesigneeResponse, IError>
) =>
  useQuery<IListDesigneeResponse, IError>(
    [ClientUserQueryKeys.GET_CLIENT_DESIGNATED_ENTITY, { clientGuid, entityGuid, params }],
    () => listDesignee(clientGuid, entityGuid, params),
    config
  )

export const useDesignatedVendorList = (
  clientGuid: string,
  userGuid: string,
  config?: UseQueryOptions<IListDesignatedVendorResponse, IError>
) =>
  useQuery<IListDesignatedVendorResponse, IError>(
    [ClientUserQueryKeys.GET_CLIENT_DESIGNATED_VENDOR, { clientGuid, userGuid }],
    () => listDesignatedVendors(clientGuid, userGuid),
    config
  )

export const useCreateDesignatedEntity = (
  config?: UseMutationOptions<undefined, IError, ICreateDesignatedEntityRequest>
) => useMutation<undefined, IError, ICreateDesignatedEntityRequest>(createDesignatedEntity, config)

export const useCreateDesignatedVendor = () =>
  useMutation<undefined, IError, ICreateDesignatedVendorRequest>(createDesignatedVendor)

export const useUpdateDesignatedEntity = (
  config?: UseMutationOptions<undefined, IError, IUpdateDesignatedEntityRequest>
) => useMutation<undefined, IError, IUpdateDesignatedEntityRequest>(updateDesignatedEntity, config)

export const useUpdateDesignatedVendor = (
  config?: UseMutationOptions<undefined, IError, IUpdateDesignatedVendorRequest>
) => useMutation<undefined, IError, IUpdateDesignatedVendorRequest>(updateDesignatedVendor, config)

export const useDestroyDesignatedEntity = (
  config?: UseMutationOptions<undefined, IError, IDestroyDesignatedEntityRequest>
) =>
  useMutation<undefined, IError, IDestroyDesignatedEntityRequest>(destroyDesignatedEntity, config)

export const useDestroyDesignatedVendor = (
  config?: UseMutationOptions<undefined, IError, IDestroyDesignatedVendorRequest>
) =>
  useMutation<undefined, IError, IDestroyDesignatedVendorRequest>(destroyDesignatedVendor, config)
