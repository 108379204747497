import { useMutation, UseMutationOptions, useQueries, useQuery, UseQueryOptions } from 'react-query'
import {
  billEventList,
  billMessageList,
  createOrgBill,
  createBillAttachment,
  createOrgBillMessage,
  updateOrgBillAction,
  destroyBillAttachment,
  listAccountClientsEntities,
  listBills,
  listBillSummary,
  listEntitiesDivisions,
  listEntitiesAccounts,
  listEntitiesPaymentTerms,
  listEntitiesVendors,
  markOrgBillMessageAsRead,
  partialUpdateVendor,
  retrieveOrgBill,
  listOrgBillMessages,
  orgBillMessageSummary,
  createBillNote,
  listBillNotes,
  updateOrgBill,
  retrieveClientEntityBillSummary,
  listOrgCustomers,
  addBulkExcludedVendor,
  addBulkExcludedClass,
  listBillAttachments,
  reverseBillPayment,
  listEmailAttachments,
  updateOrgBillRevise,
  listEntityClassifications,
} from '../api'
import {
  IBillMessageReadRequest,
  IBillRequest,
  ICreateBillAttachmentRequest,
  ICreateBillMessageRequest,
  IOrgBillActionRequest,
  IDeleteBillAttachmentRequest,
  IError,
  IListBillEventResponse,
  IListBillMessageResponse,
  IListBillRequest,
  IListBillSummaryResponse,
  IListGuidWithName,
  IListVendors,
  IUpdateVendorRequest,
  IBill,
  IBillMessageSummaryResponse,
  IBillUpdateRequest,
  IListEntityResponse,
  ICreateBillNoteRequest,
  IListBillNotesResponse,
  IClientEntityBillSummaryRequest,
  IListClientEntityBillSummaryResponse,
  ICustomerRequest,
  IListCustomerResponse,
  IListBills,
  IBulkExcludedVendorRequest,
  IBulkExcludedDivisionRequest,
  IListEntityRequest,
  IListVendorRequest,
  IListBillAttachmentRequest,
  IListBillAttachmentResponse,
  IOrgReverseBillPayment,
  IOrgBillReviseRequest,
  IListEntityClassificationsResponse,
} from '../interface'

export const OrgBillQueryKeys = {
  GET_BILL_LIST: 'GET_ORG_BILL_LIST',
  GET_BILL: 'GET_ORG_BILL',
  GET_BILL_MESSAGE_SUMMARY: 'GET_ORG_BILL_MESSAGE_SUMMARY',
  GET_ENTITIES_LIST: 'GET_ORG_ENTITIES_LIST',
  GET_ACCOUNT_LIST: 'GET_ACCOUNT_LIST',
  GET_VENDOR_LIST: 'GET_ORG_VENDOR_LIST',
  GET_PAYMENT_TERM_LIST: 'GET_ORG_PAYMENT_TERM_LIST',
  GET_DIVISION_LIST: 'GET_ORG_DIVISION_LIST',
  GET_BILL_SUMMARY_LIST: 'GET_ORG_BILL_SUMMARY_LIST',
  GET_BILL_EVENT_LIST: 'GET_ORG_BILL_EVENT_LIST',
  GET_BILL_MESSAGE_LIST: 'GET_ORG_BILL_MESSAGE_LIST',
  GET_GLOBAL_MESSAGE_LIST: 'GET_GLOBAL_ORG_MESSAGE_LIST',
  GET_BILL_NOTE_LIST: 'GET_ORG_BILL_NOTE_LIST',
  GET_CLIENT_ENTITY_BILL_SUMMARY: 'GET_CLIENT_ENTITY_BILL_SUMMARY',
  GET_CUSTOMERS_LIST: 'GET_CUSTOMERS_LIST',
  GET_ACCOUNT_BALANCE_LIST: 'GET_CUSTOMERS_LIST',
  GET_BILL_ATTACHMENT_LIST: 'GET_BILL_ATTACHMENT_LIST',
  GET_EMAIL_ATTACHMENT_LIST: 'GET_EMAIL_ATTACHMENT_LIST',
  GET_ENTITY_CLASSIFICATION_LIST: 'GET_ENTITY_CLASSIFICATION_LIST',
}

export const useOrgListBills = (
  orgGuid: string,
  payload: IListBillRequest,
  config?: UseQueryOptions<IListBills, IError, IListBills>
) =>
  useQuery(
    [OrgBillQueryKeys.GET_BILL_LIST, orgGuid, payload],
    () => listBills(orgGuid, payload),
    config
  )

export const useRetrieveOrgBill = (
  orgGuid: string,
  billGuid: string,
  config?: UseQueryOptions<IBill, unknown, IBill>
) =>
  useQuery(
    [OrgBillQueryKeys.GET_BILL, { orgGuid, billGuid }],
    () => retrieveOrgBill(orgGuid, billGuid),
    {
      ...config,
      enabled: !!billGuid,
    }
  )

export const useClientEntitiesList = (
  clientGuid: string,
  params?: IListEntityRequest,
  enableRefetchInterval?: boolean
) =>
  useQuery<IListEntityResponse, IError>(
    [OrgBillQueryKeys.GET_ENTITIES_LIST, clientGuid, params, enableRefetchInterval],
    () => listAccountClientsEntities(clientGuid, params),
    { enabled: !!clientGuid, refetchInterval: enableRefetchInterval && 1000 * 60 }
  )

export const useListAccounts = (
  orgGuid: string,
  entityGuid: string,
  page: number,
  pageSize: number,
  archived?: boolean,
  search?: string
) =>
  useQuery(
    [OrgBillQueryKeys.GET_ACCOUNT_LIST, orgGuid, entityGuid, page, search],
    () => listEntitiesAccounts(orgGuid, entityGuid, page, pageSize, archived, search),
    {
      enabled: !!entityGuid,
    }
  )

export const useListEntitiesVendor = (
  orgGuid: string,
  entityGuid: string,
  params: IListVendorRequest
) =>
  useQuery<IListVendors, IError>(
    [OrgBillQueryKeys.GET_VENDOR_LIST, orgGuid, entityGuid, params],
    () => listEntitiesVendors(orgGuid, entityGuid, params),
    { enabled: !!entityGuid }
  )

export const useUpdateVendor = () =>
  useMutation<undefined, IError, IUpdateVendorRequest>(partialUpdateVendor)

export const useAddBulkExcludedVendor = () =>
  useMutation<undefined, IError, IBulkExcludedVendorRequest>(addBulkExcludedVendor)

export const useAddBulkExcludedDivision = () =>
  useMutation<undefined, IError, IBulkExcludedDivisionRequest>(addBulkExcludedClass)

export const useListEntitiesPaymentTerms = (
  orgGuid: string,
  entityGuid: string,
  page: number,
  config?: UseQueryOptions<IListGuidWithName>
) =>
  useQuery(
    [OrgBillQueryKeys.GET_PAYMENT_TERM_LIST, orgGuid, entityGuid, page],
    () => listEntitiesPaymentTerms(orgGuid, entityGuid, page),
    config
  )

export const useListEntitiesDivision = (
  orgGuid: string,
  entityGuid: string,
  page: number,
  page_size: number,
  archived?: boolean,
  search?: string
) =>
  useQuery(
    [OrgBillQueryKeys.GET_DIVISION_LIST, orgGuid, entityGuid, page, page_size, archived, search],
    () => listEntitiesDivisions(orgGuid, entityGuid, page, page_size, archived, search),
    {
      enabled: !!entityGuid,
    }
  )

export const useEntityResults = (orgGuid: string, entityGuid: string, page: number) =>
  useQueries([
    {
      queryKey: OrgBillQueryKeys.GET_VENDOR_LIST,
      queryFn: () => listEntitiesVendors(orgGuid, entityGuid, { page, page_size: 1000 }),
    },
    {
      queryKey: OrgBillQueryKeys.GET_ACCOUNT_LIST,
      queryFn: () => listEntitiesAccounts(orgGuid, entityGuid, page, 1000),
    },
    {
      queryKey: OrgBillQueryKeys.GET_PAYMENT_TERM_LIST,
      queryFn: () => listEntitiesPaymentTerms(orgGuid, entityGuid, page),
    },
    {
      queryKey: OrgBillQueryKeys.GET_DIVISION_LIST,
      queryFn: () => listEntitiesDivisions(orgGuid, entityGuid, page, 1000),
    },
  ])

export const useCreateBill = (
  config?: UseMutationOptions<IBill, IError, { orgGuid: string; payload: IBillRequest }>
) => useMutation<IBill, IError, { orgGuid: string; payload: IBillRequest }>(createOrgBill, config)

export const useUpdateBill = (
  config?: UseMutationOptions<
    IBill,
    IError,
    { orgGuid: string; billGuid: string; payload: IBillUpdateRequest }
  >
) =>
  useMutation<IBill, IError, { orgGuid: string; billGuid: string; payload: IBillUpdateRequest }>(
    updateOrgBill,
    config
  )

export const useListBillSummaries = (orgGuid: string, params: IListBillRequest) =>
  useQuery<IListBillSummaryResponse, IError>(
    [OrgBillQueryKeys.GET_BILL_SUMMARY_LIST, orgGuid, params],
    () => listBillSummary(orgGuid, params)
  )

export const useOrgBillActionUpdate = () =>
  useMutation<undefined, IError, IOrgBillActionRequest>(updateOrgBillAction)

export const useOrgBillActionRevise = () =>
  useMutation<undefined, IError, IOrgBillReviseRequest>(updateOrgBillRevise)

export const useReverseBillPayment = () =>
  useMutation<undefined, IError, IOrgReverseBillPayment>(reverseBillPayment)

export const useBillEventList = (orgGuid: string, billGuid: string, page: number) =>
  useQuery<IListBillEventResponse, IError>(
    [OrgBillQueryKeys.GET_BILL_EVENT_LIST, orgGuid, billGuid, page],
    () => billEventList(orgGuid, billGuid, page)
  )

export const useCreateOrgBillMessage = () =>
  useMutation<undefined, IError, ICreateBillMessageRequest>(createOrgBillMessage)

export const useOrgBillMessageList = (orgGuid: string, billGuid: string, page: number) =>
  useQuery<IListBillMessageResponse, IError>(
    [OrgBillQueryKeys.GET_BILL_MESSAGE_LIST, orgGuid, billGuid, page],
    () => billMessageList(orgGuid, billGuid, page)
  )

export const useMarkOrgBillMessageAsRead = () =>
  useMutation<undefined, IError, IBillMessageReadRequest>(markOrgBillMessageAsRead)

export const useCreateBillAttachment = () =>
  useMutation<undefined, IError, ICreateBillAttachmentRequest>(createBillAttachment)

export const useDestroyBillAttachment = () =>
  useMutation<undefined, IError, IDeleteBillAttachmentRequest>(destroyBillAttachment)

export const useGlobalBillMessageSummary = (orgGuid: string) =>
  useQuery<IBillMessageSummaryResponse, IError>(
    [OrgBillQueryKeys.GET_BILL_MESSAGE_SUMMARY, orgGuid],
    () => orgBillMessageSummary(orgGuid),
    { enabled: !!orgGuid }
  )

export const useGlobalBillMessageList = (orgGuid: string) =>
  useQuery<IListBillMessageResponse, IError>(
    [OrgBillQueryKeys.GET_GLOBAL_MESSAGE_LIST, orgGuid],
    () => listOrgBillMessages(orgGuid)
  )

export const useCreateOrgBillNote = () =>
  useMutation<undefined, IError, ICreateBillNoteRequest>(createBillNote)

export const useOrgBillNotesList = (orgGuid: string, billGuid: string, page: number) =>
  useQuery<IListBillNotesResponse, IError>(
    [OrgBillQueryKeys.GET_BILL_NOTE_LIST, orgGuid, billGuid, page],
    () => listBillNotes(orgGuid, billGuid, page)
  )

export const useClientEntityBillSummary = (
  orgGuid: string,
  payload: IClientEntityBillSummaryRequest
) =>
  useQuery<IListClientEntityBillSummaryResponse, IError>(
    [OrgBillQueryKeys.GET_CLIENT_ENTITY_BILL_SUMMARY, orgGuid, payload],
    () => retrieveClientEntityBillSummary(orgGuid, payload)
  )

export const useCustomerList = (orgGuid: string, entityGuid: string, payload: ICustomerRequest) =>
  useQuery<IListCustomerResponse, IError>(
    [OrgBillQueryKeys.GET_CUSTOMERS_LIST, orgGuid, entityGuid, payload],
    () => listOrgCustomers(orgGuid, entityGuid, payload)
  )

export const useBillAttachmentList = (orgGuid: string, payload: IListBillAttachmentRequest) =>
  useQuery<IListBillAttachmentResponse, IError>(
    [OrgBillQueryKeys.GET_BILL_ATTACHMENT_LIST, orgGuid, payload],
    () => listBillAttachments(orgGuid, payload)
  )

export const useEmailAttachmentList = (orgGuid: string, payload: IListBillAttachmentRequest) =>
  useQuery<IListBillAttachmentResponse, IError>(
    [OrgBillQueryKeys.GET_EMAIL_ATTACHMENT_LIST, orgGuid, payload],
    () => listEmailAttachments(orgGuid, payload)
  )

export const useListEntityClassifications = () =>
  useQuery<IListEntityClassificationsResponse, IError, { value: string; label: string }[]>(
    [OrgBillQueryKeys.GET_ENTITY_CLASSIFICATION_LIST],
    () => listEntityClassifications(),
    {
      select: (data) =>
        Object.entries(data).map(([key, value]) => ({
          value: key,
          label: value,
        })),
    }
  )
