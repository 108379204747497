import { useContext, useMemo } from 'react'
import { OrgGuidContext } from '../../../contexts/OrgGuidContext'
import { FeatureFlaggingContext } from '../../../contexts/FeatureFlaggingContext'
import { ERole } from '../../../service/enum'

export const useOrgPages = () => {
  const { orgUser } = useContext(OrgGuidContext)
  const { isVendorManagementEnabled } = useContext(FeatureFlaggingContext)

  const userRole = orgUser?.role

  const isAdmin = useMemo(() => {
    return userRole === ERole.admin || userRole === ERole.super_admin
  }, [userRole])

  const org_pages = useMemo(() => {
    return [
      { name: 'Clients', url: '/clients', activeUrl: ['clients'] },
      {
        name: 'BillPay',
        url: '#',
        subPages: [
          { name: 'Bills', url: '/billing' },
          { name: 'Commitments', url: '/commitments?page=1' },
          ...(isVendorManagementEnabled
            ? [{ name: 'Parent Vendors', url: '/parent-vendors' }]
            : []),
          ...(isAdmin ? [{ name: 'Parent Institutions', url: '/parent-institutions' }] : []),
        ],
        activeUrl: ['billing', 'commitments', 'parent-vendors', 'parent-institutions'],
      },
      {
        name: 'Workflow',
        url: '#',
        subPages: [
          { name: 'Reconciliation', url: '/workflow/reconciliation-workflow?tab=0&page=1' },
          { name: 'Review', url: '/workflow/review?tab=0&page=1' },
          { name: 'Reports', url: '/workflow/report-reminders?tab=0&page=1' },
        ],
        activeUrl: [
          'reports?report-tab=2',
          'reports?report-tab=1',
          'review',
          'report-reminder',
          'reconciliation',
          'workflow',
        ],
      },
      {
        name: 'Reporting',
        url: '/reports?report-tab=0',
        activeUrl: ['reports?report-tab=0', 'reports/client'],
      },
    ]
  }, [isVendorManagementEnabled, isAdmin])

  return { isAdmin, org_pages }
}
