import React, { lazy, Suspense } from 'react'
import { Route, Switch } from 'react-router-dom'

import OrgDefaultSidebar from '../components/layout-blueprints/OrgDefaultSidebar'
import SidebarWithoutMenu from '../components/layout-blueprints/SidebarWithoutMenu'
import LoadingIndicator from '../components/loading/LoadingIndicator'
import PrivateRoute from '../components/route/PrivateRoute'
import StaffRoute from '../components/route/StaffRoute'
import { OrgGuidContextProvider } from '../contexts/OrgGuidContext'
import QBOSyncRedirect from '../components/qb-online/QBOSyncRedirect'
import { FeatureFlaggingContextProvider } from '../contexts/FeatureFlaggingContext'

const Clients = lazy(() => import('../containers/org/Clients'))

const CommitmentDetails = lazy(() => import('../containers/org/CommitmentDetails'))
const Commitments = lazy(() => import('../containers/org/Commitments'))
const CommitmentForm = lazy(() => import('../containers/org/Commitments/CommitmentForm/Form'))
const Profile = lazy(() => import('../containers/general/Profile'))
const Organizations = lazy(() => import('../containers/org/Organizations'))
const NonStaffUsers = lazy(() => import('../containers/org/NonStaffUsers'))
const ClientDetails = lazy(() => import('../containers/org/ClientDetails'))
const ClientArchivedPreBills = lazy(() => import('../containers/org/ClientArchivedPreBills'))
const Billing = lazy(() => import('../containers/org/Billing'))
const ClientReports = lazy(() => import('../containers/org/ClientReports'))
const QBOConnectRedirect = lazy(() => import('../components/qb-online/QBOConnectRedirect'))
const CreateNewBill = lazy(() => import('../containers/org/Billing/NewBill'))
const ExistingBill = lazy(() => import('../containers/org/Billing/ExistingBill'))
const CommitmentBill = lazy(() => import('../containers/org/Billing/CommitmentBill'))
const ArchivedClients = lazy(() => import('../containers/org/Clients/InactiveClients'))
const OrgClientReports = lazy(() => import('../containers/org/ClientReports/OrgClientReports'))
const OrgArchivedCommitments = lazy(
  () => import('../containers/org/Commitments/OrgInactiveCommitments')
)
const ParentVendors = lazy(() => import('../containers/org/ParentVendors'))
const ParentInstitutions = lazy(() => import('../containers/org/ParentInstitutions'))
const InactiveParentVendors = lazy(
  () => import('../containers/org/ParentVendors/InactiveParentVendorsTable')
)
const ParentVendorDetails = lazy(() => import('../containers/org/ParentVendorDetails'))
//Org Settings
const OrgSettings = lazy(() => import('../containers/org/OrgSettings'))
const EditPermissionGroup = lazy(() => import('../containers/org/Groups/EditGroupPermission'))
const AddPermissionGroup = lazy(() => import('../containers/org/Groups/AddGroupPermission'))
const StaffProfile = lazy(() => import('../containers/org/StaffProfile'))
const Support = lazy(() => import('../containers/client/Support'))
const AuditHistory = lazy(() => import('../containers/org/AuditHistory'))
// Reporting Tracker
const ParentReportReminders = lazy(() => import('../containers/org/ParentReportReminders'))
const ReconciliationReminders = lazy(() => import('../containers/org/ReconciliationReminders'))
const ReconciliationWorkflow = lazy(() => import('../containers/org/ReconciliationWorkflow'))
const InactiveReconciliation = lazy(
  () => import('../containers/org/ReconciliationWorkflow/InactiveReconciliation')
)
const ReviewWorkflow = lazy(() => import('../containers/org/ReviewWorkflow'))
const NewReminderForm = lazy(() => import('../containers/org/ReportReminders/NewReminderForm'))
const ReportReminderDetails = lazy(() => import('../containers/org/ReportReminderDetails'))
const ReconciliationDetails = lazy(
  () => import('../containers/org/ReportReminderDetails/ReconciliationDetails')
)
const InactiveReportReminders = lazy(
  () => import('../containers/org/ParentReportReminders/InactiveReportReminders')
)
// Advanced Search
const AdvancedSearch = lazy(() => import('../containers/client/AdvancedSearch'))
// Admin Dashboard
const ManagerDashboard = lazy(() => import('../containers/org/Dashboard/Manager'))
const AssociateDashboard = lazy(() => import('../containers/org/Dashboard/Associate'))
// Onboarding
const Onboarding = lazy(() => import('../containers/org/Onboarding'))

export const orgGuidRequiredRoutes = {
  clients: '/clients',
  commitments: '/commitments',
  details: '/details',
  settings: '/settings',
  groups: '/groups',
  userManagement: '/user-management',
  associateDashboard: '/associate-dashboard',
  managerDashboard: '/manager-dashboard',
  billing: '/billing',
  reports: '/reports',
  support: '/support',
  clientReports: '/client-reports',
  billingPreBillInbox: '/billing/pre-bill-inbox',
  billingClientApproval: '/billing/client-approval',
  billingVerbalApproval: '/billing/verbal-approval',
  billingPaymentPending: '/billing/payment-pending',
  billingOrgApproval: '/billing/org-approval',
  billingPOAApproval: '/billing/poa-approval',
  billingPaid: '/billing/paid',
  billingRejected: '/billing/rejected',
  advancedSearch: '/advanced-search',
  auditHistory: '/audit-history',
  parentVendors: '/parent-vendors',
  parentInstitutions: '/parent-institutions',
  reportReminders: '/workflow/report-reminders',
  reconciliationReminders: '/workflow/reconciliation-reminders',
  reconciliationWorkflow: '/workflow/reconciliation-workflow',
  reviewWorkflow: '/workflow/review',
}

export const orgRoutesPath = {
  profile: '/profile',
  // TODO: Remove this path once migration to new UI is complete
  profileOld: '/profile-old',
  myOrganizations: '/my-organizations',
  nonStaffUsers: '/non-staff-users',
  qboConnect: '/integrations/qbonline/connect',
  qboSync: '/integrations/qbonline/sync',
  clientOnboarding: '/client-onboarding',
  ...orgGuidRequiredRoutes,
}

const {
  clients,
  commitments,
  profile,
  profileOld,
  myOrganizations,
  settings,
  userManagement,
  nonStaffUsers,
  billing,
  associateDashboard,
  managerDashboard,
  groups,
  qboConnect,
  reports,
  clientReports,
  qboSync,
  support,
  advancedSearch,
  auditHistory,
  clientOnboarding,
  parentVendors,
  parentInstitutions,
  reportReminders,
  reconciliationReminders,
  reconciliationWorkflow,
  reviewWorkflow,
} = orgRoutesPath

const OrgRoutes = (
  <Route
    path={[
      clients,
      commitments,
      profile,
      profileOld,
      myOrganizations,
      settings,
      userManagement,
      nonStaffUsers,
      billing,
      associateDashboard,
      managerDashboard,
      groups,
      qboConnect,
      reports,
      clientReports,
      qboSync,
      support,
      advancedSearch,
      auditHistory,
      clientOnboarding,
      parentVendors,
      parentInstitutions,
      reportReminders,
      reconciliationReminders,
      reconciliationWorkflow,
      reviewWorkflow,
    ]}
  >
    <OrgGuidContextProvider>
      <FeatureFlaggingContextProvider>
        <Route
          path={[
            clients,
            commitments,
            parentVendors,
            parentInstitutions,
            profile,
            profileOld,
            billing,
            associateDashboard,
            managerDashboard,
            reports,
            clientReports,
            qboConnect,
            qboSync,
            advancedSearch,
            reportReminders,
            reconciliationReminders,
            reconciliationWorkflow,
            reviewWorkflow,
          ]}
        >
          <OrgDefaultSidebar>
            <Suspense fallback={<LoadingIndicator />}>
              <Switch>
                <PrivateRoute exact path={managerDashboard} component={ManagerDashboard} />
                <PrivateRoute exact path={associateDashboard} component={AssociateDashboard} />
                <PrivateRoute exact path={clients} component={Clients} />
                <PrivateRoute exact path={parentVendors} component={ParentVendors} />
                <PrivateRoute exact path={parentInstitutions} component={ParentInstitutions} />
                <PrivateRoute
                  exact
                  path={`${parentVendors}/inactive`}
                  component={InactiveParentVendors}
                />
                <PrivateRoute
                  exact
                  path={`${parentVendors}/:guid`}
                  component={ParentVendorDetails}
                />
                <PrivateRoute exact path={commitments} component={Commitments} />
                <PrivateRoute
                  exact
                  path={`${commitments}/commitment-form`}
                  component={CommitmentForm}
                />
                <PrivateRoute
                  exact
                  path={`${commitments}/commitment-form/from-new-bill/:billGuid`}
                  component={CommitmentForm}
                />
                <PrivateRoute
                  exact
                  path={`${commitments}/archived-commitments`}
                  component={OrgArchivedCommitments}
                />
                <PrivateRoute
                  exact
                  path={`${commitments}/:commitmentId`}
                  component={CommitmentDetails}
                />
                <PrivateRoute
                  exact
                  path={`${commitments}/:commitmentId/new-bill`}
                  component={CommitmentBill}
                />
                <PrivateRoute
                  exact
                  path={`${commitments}/:commitmentId/new-bill/:commitmentBillId`}
                  component={CommitmentBill}
                />
                <PrivateRoute exact path={`${clients}/:clientGuid`} component={ClientDetails} />
                <PrivateRoute
                  exact
                  path={`${clients}/:clientGuid/new-bill`}
                  component={CreateNewBill}
                />
                <PrivateRoute
                  exact
                  path={`${clients}/:clientGuid/convert-pre-bill/:emailGuid`}
                  component={CreateNewBill}
                />
                <PrivateRoute
                  exact
                  path={`${clients}/:clientGuid/:userType/bill/:billGuid`}
                  component={ExistingBill}
                />
                <PrivateRoute
                  exact
                  path={`${clients}/:clientGuid/archived-pre-bills`}
                  component={ClientArchivedPreBills}
                />
                <PrivateRoute
                  exact
                  path={`${clients}/all-clients/archived-pre-bills`}
                  component={ClientArchivedPreBills}
                />
                <PrivateRoute
                  exact
                  path={`${clients}/archived/client-list/:clientType`}
                  component={ArchivedClients}
                />
                <PrivateRoute exact path={profile} component={Profile} />
                <PrivateRoute path={billing} component={Billing} />
                <PrivateRoute exact path={reports} component={ClientReports} />
                <PrivateRoute
                  exact
                  path={`${reports}/new-reminder-form`}
                  component={NewReminderForm}
                />
                <PrivateRoute
                  exact
                  path={`${reports}/report-reminder/:reportReminderId`}
                  component={ReportReminderDetails}
                />
                <PrivateRoute
                  exact
                  path={`${reports}/report-reminder/reconciliation/:reportReminderId`}
                  component={ReconciliationDetails}
                />
                <PrivateRoute
                  exact
                  path={`${reports}/client/:clientGuid`}
                  component={OrgClientReports}
                />
                <PrivateRoute path={qboConnect} component={QBOConnectRedirect} />
                <PrivateRoute path={qboSync} component={QBOSyncRedirect} />
                <PrivateRoute exact path={`${advancedSearch}`} component={AdvancedSearch} />
                <PrivateRoute exact path={reportReminders} component={ParentReportReminders} />
                <PrivateRoute
                  exact
                  path={reconciliationReminders}
                  component={ReconciliationReminders}
                />
                <PrivateRoute
                  exact
                  path={reconciliationWorkflow}
                  component={ReconciliationWorkflow}
                />
                <PrivateRoute
                  exact
                  path={`${reconciliationWorkflow}/inactive`}
                  component={InactiveReconciliation}
                />
                <PrivateRoute
                  exact
                  path={`${reportReminders}/inactive`}
                  component={InactiveReportReminders}
                />
                <PrivateRoute exact path={reviewWorkflow} component={ReviewWorkflow} />
              </Switch>
            </Suspense>
          </OrgDefaultSidebar>
        </Route>
        <Route exact path={[myOrganizations]}>
          <SidebarWithoutMenu title="Switch to Organization">
            <Suspense fallback={<LoadingIndicator />}>
              <Switch>
                <PrivateRoute exact path={myOrganizations} component={Organizations} />
              </Switch>
            </Suspense>
          </SidebarWithoutMenu>
        </Route>
        <Route path={[settings, userManagement, groups, support, auditHistory]}>
          <OrgDefaultSidebar>
            <Suspense fallback={<LoadingIndicator />}>
              <Switch>
                <PrivateRoute exact path={settings} component={OrgSettings} />
                <PrivateRoute
                  exact
                  path={`${settings}/org-edit-group/:orgGuid/group/:groupGuid`}
                  component={EditPermissionGroup}
                />
                <PrivateRoute
                  exact
                  path={`${settings}/org-add-group/:orgGuid`}
                  component={AddPermissionGroup}
                />
                <PrivateRoute exact path={`${userManagement}/:userId/`} component={StaffProfile} />
                <PrivateRoute exact path={support} component={Support} />
                <PrivateRoute exact path={auditHistory} component={AuditHistory} />
              </Switch>
            </Suspense>
          </OrgDefaultSidebar>
        </Route>
        <Route path={[nonStaffUsers]}>
          <SidebarWithoutMenu title="Mask to another User">
            <Suspense fallback={<LoadingIndicator />}>
              <Switch>
                <StaffRoute exact path={nonStaffUsers} component={NonStaffUsers} />
              </Switch>
            </Suspense>
          </SidebarWithoutMenu>
        </Route>
        <Route path={[clientOnboarding]}>
          <SidebarWithoutMenu title="Onboarding">
            <Suspense fallback={<LoadingIndicator />}>
              <PrivateRoute
                exact
                path={`${clientOnboarding}/client/:clientGuid`}
                component={Onboarding}
              />
              <PrivateRoute
                exact
                path={`${clientOnboarding}/commitments/commitment-form`}
                component={CommitmentForm}
              />
              <PrivateRoute
                exact
                path={`${clientOnboarding}/reports/new-reminder-form`}
                component={NewReminderForm}
              />
              <PrivateRoute
                exact
                path={`${clientOnboarding}/reports/report-reminder/:reportReminderId`}
                component={ReportReminderDetails}
              />
              <PrivateRoute
                exact
                path={`${clientOnboarding}/reports/report-reminder/reconciliation/:reportReminderId`}
                component={ReconciliationDetails}
              />
            </Suspense>
          </SidebarWithoutMenu>
        </Route>
      </FeatureFlaggingContextProvider>
    </OrgGuidContextProvider>
  </Route>
)

export default OrgRoutes
