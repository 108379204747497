import React, { useContext, useMemo, useState } from 'react'
import { NavLink } from 'react-router-dom'
import {
  Toolbar,
  IconButton,
  Typography,
  Menu,
  Container,
  MenuItem,
  Box,
  styled,
  List,
  ListItem,
  Button,
  MenuList,
} from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu'
import SidebarHeader from '../SidebarHeader'
import HeaderUserbox from '../OrgHeaderUserbox'
import {
  box_minimized_style,
  box_maximized_style,
  menu_style,
} from '../../layout-components/constants'
import { OrgGuidContext } from '../../../contexts/OrgGuidContext'
import { StyledAppBar } from '../../../utils/styles'
import { useOrgPages } from './useOrgPages'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp'
import clsx from 'clsx'

export const StyledItemText = styled(Typography)(() => ({
  marginTop: 8,
  marginBottom: 8,
  marginLeft: 8,
  marginRight: 8,
  fontSize: 16,
  color: 'black',
}))

export const StyledContainer = styled(Container)(() => ({
  '& > .MuiToolbar-root': {
    alignItems: 'stretch',
  },
}))

export const StyledList = styled(List)(() => ({
  display: 'flex',
  alignItems: 'center',
  padding: 0,
  '& .MuiTypography-root': {
    fontWeight: 350,
  },
  '& > .MuiListItem-root': {
    height: '100%',
    padding: '0',
    margin: '0 8px',
    '& > .MuiButton-root': {
      padding: '23px 8px',
      transform: 'none !important',
      color: '#BFCBD3',
      height: 'auto',
      '&.active': {
        color: '#fff',
      },
      '& .MuiSvgIcon-root': {
        fontSize: '24px',
      },
      '&:hover': {
        background: 'rgba(255,255,255,0.08)',
      },
    },
    '& > a': {
      color: '#BFCBD3',
      padding: '23px 8px',
      '&:hover': {
        background: 'rgba(255,255,255,0.08)',
      },
    },
    '&.active': {
      '& > a': {
        color: '#fff',
      },
      '& > .MuiButton-root': {
        color: '#fff',
      },
      '&:after': {
        content: '""',
        position: 'absolute',
        width: '100%',
        height: '2px',
        backgroundColor: 'white',
        left: 0,
        bottom: 0,
      },
    },
  },
}))

export const StyledMenu = styled(Menu)(() => ({
  '& .MuiMenuItem-root': {
    display: 'block',
  },
}))

interface PageWithSubPages {
  name: string
  subPages: {
    disabled: boolean | undefined
    name: string
    url: string
  }[]
}

const OrgSidebarMenu: React.FC = () => {
  const { isAssociate, isManagerLead } = useContext(OrgGuidContext)
  const { org_pages } = useOrgPages()
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null)
  const [anchorElSubPages, setAnchorElSubPages] = React.useState<null | HTMLElement>(null)
  const [currentPage, setCurrentPage] = useState(null)
  const [submenuOpenState, setSubmenuOpenState] = useState<{ [key: number]: boolean }>({})

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget)
  }

  const handleCloseNavMenu = () => {
    setAnchorElNav(null)
  }

  const handleOpenSubPages = (event: React.MouseEvent<HTMLElement>, page: any, index: number) => {
    setAnchorElSubPages(event.currentTarget)
    setCurrentPage(page)
    setSubmenuOpenState((prevOpenState) => ({ ...prevOpenState, [index]: true }))
  }

  const handleCloseSubPages = () => {
    setAnchorElSubPages(null)
    setSubmenuOpenState({})
  }

  const allPages = useMemo(() => {
    if (isAssociate || isManagerLead) {
      return [...org_pages]
    }
    return org_pages
  }, [isAssociate, isManagerLead, org_pages])

  return (
    <>
      <StyledAppBar>
        <StyledContainer maxWidth={false}>
          <Toolbar disableGutters>
            <Box flex={1} sx={box_minimized_style}>
              <SidebarHeader />
            </Box>
            <Box flex={1} justifyContent="end" alignItems="center" sx={box_minimized_style}>
              <Box>
                <IconButton
                  size="large"
                  aria-label="menu-bar"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleOpenNavMenu}
                  className="icon-button"
                  style={{ color: '#fff' }}
                >
                  <MenuIcon />
                </IconButton>
              </Box>

              <StyledMenu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={menu_style}
              >
                {allPages.map((page, index) => (
                  <MenuItem key={index}>
                    {page && 'subPages' in page ? (
                      <>
                        <NavLink className="nav-link-simple" to="#">
                          <StyledItemText>{page.name}</StyledItemText>
                        </NavLink>
                        <MenuList>
                          {(page as PageWithSubPages).subPages?.map((subPage, subIndex) => (
                            <MenuItem key={subIndex} disabled={subPage?.disabled}>
                              <NavLink
                                className="nav-link-simple"
                                to={subPage.url}
                                onClick={handleCloseNavMenu}
                              >
                                <StyledItemText>{subPage.name}</StyledItemText>
                              </NavLink>
                            </MenuItem>
                          ))}
                        </MenuList>
                      </>
                    ) : (
                      <NavLink
                        className="nav-link-simple"
                        to={page.url}
                        onClick={handleCloseNavMenu}
                      >
                        <StyledItemText>{page.name}</StyledItemText>
                      </NavLink>
                    )}
                  </MenuItem>
                ))}
              </StyledMenu>
            </Box>
            <Box sx={box_maximized_style}>
              <SidebarHeader />
            </Box>
            <Box flex={1} sx={box_maximized_style}>
              <StyledList>
                {allPages.map((page, index) => (
                  <ListItem
                    key={index}
                    className={clsx('nav-list', {
                      active:
                        Array.isArray(page.activeUrl) &&
                        page.activeUrl.some((url) => window.location.href.includes(url)),
                    })}
                  >
                    {page && 'subPages' in page ? (
                      <>
                        <Button
                          className={clsx('nav-button', { active: submenuOpenState[index] })}
                          onClick={(event) => handleOpenSubPages(event, page, index)}
                          aria-label={`submenu-bar-${index}`}
                          aria-controls={`submenu-${index}`}
                          aria-haspopup="true"
                          endIcon={
                            submenuOpenState[index] ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />
                          }
                          disableElevation={true}
                        >
                          <Typography>{page.name}</Typography>
                        </Button>
                        {currentPage === page && (
                          <Menu
                            id={`submenu-${index}`}
                            anchorEl={anchorElSubPages}
                            open={Boolean(anchorElSubPages)}
                            onClose={handleCloseSubPages}
                          >
                            {(page as PageWithSubPages).subPages.map((subPage, subIndex) => (
                              <MenuItem
                                key={subIndex}
                                disabled={subPage?.disabled}
                                style={{ display: 'block', width: '100%' }}
                              >
                                <NavLink
                                  className="nav-link-simple"
                                  to={subPage.url}
                                  onClick={handleCloseSubPages}
                                >
                                  <StyledItemText>{subPage.name}</StyledItemText>
                                </NavLink>
                              </MenuItem>
                            ))}
                          </Menu>
                        )}
                      </>
                    ) : (
                      <NavLink exact className="nav-link-simple" to={page.url}>
                        <Typography>{page.name}</Typography>
                      </NavLink>
                    )}
                  </ListItem>
                ))}
              </StyledList>
            </Box>
            <Box sx={box_maximized_style}>
              <HeaderUserbox />
            </Box>
          </Toolbar>
        </StyledContainer>
      </StyledAppBar>
    </>
  )
}

export default OrgSidebarMenu
