import { clientRoutesPath } from '../../routes/ClientRoutes'

export const box_minimized_style = {
  display: { xs: 'flex', md: 'none' },
}

export const box_maximized_style = {
  display: { xs: 'none', md: 'flex' },
}

export const menu_style = {
  display: { xs: 'block', md: 'none' },
}

type Page = {
  name: string
  url: string
  disabled?: boolean
  subPages?: { name: string; url: string; disabled?: boolean }[]
  activeUrl?: string[]
}

export const client_pages: Page[] = [
  { name: 'Bills', url: `${clientRoutesPath.bills + '/all-entities'}`, activeUrl: ['bill'] },
  {
    name: 'Commitments',
    url: `${clientRoutesPath.commitments + '/all-entities'}`,
    activeUrl: ['commitments'],
  },
  {
    name: 'Reports',
    url: `${clientRoutesPath.reports}`,
    activeUrl: ['reports'],
  },
  {
    name: 'Financial Accounts',
    url: `${clientRoutesPath.financialAccounts}`,
    activeUrl: ['financial-accounts'],
  },
]
